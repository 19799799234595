export const LoginForm = 'login';
export const RegisterForm = 'register';
export const ForgotPasswordForm = 'forgot-password';

export type FormType = typeof LoginForm | typeof RegisterForm | typeof ForgotPasswordForm;

export type FormValues = {
  email: string;
  password: string;
};

export const emptyValues: FormValues = {
  email: '',
  password: ''
};
