














import { Component, Vue } from 'vue-property-decorator';
import { PhoneIcon, MailIcon } from 'vue-feather-icons';

@Component({
  components: {
    PhoneIcon,
    MailIcon
  }
})
export default class Help extends Vue {}
