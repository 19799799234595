




















































































































































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import store from '@/store';
import UserService from '@/services/user.service';
import { email, minLength, required } from 'vuelidate/lib/validators';
import Heading from '@/components/checkout/layout/page/Heading.vue';
import TextField from '@/components/ui/form/TextField.vue';
import Button from '@/components/ui/Button.vue';
import SummaryBox from '@/components/checkout/summary/SummaryBox.vue';
import Help from '@/components/checkout/Help.vue';
import GoogleSignIn from '@/components/googleSignIn.vue';
import { Car } from '@/store/types';
import { ChevronLeftIcon, ChevronRightIcon, HeartIcon } from 'vue-feather-icons';
import { HomeRoute } from '@/router/routes';
import { ForgotPasswordForm, FormType, LoginForm, RegisterForm } from '@/components/screens/checkout/CheckoutStepLogin';
import { Route } from 'vue-router';
import { AuthMixin } from '@/mixins/user/Auth.mixin';

@Component({
  components: {
    SummaryBox,
    Heading,
    GoogleSignIn,
    TextField,
    Button,
    Help,
    ChevronLeftIcon,
    ChevronRightIcon,
    HeartIcon
  },
  validations: {
    form: {
      email: { required, email },
      password: {
        required,
        minLength: minLength(8),
        isValid(value) {
          return (
            /^[A-Za-z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]*$/.test(value) &&
            /[a-z]/.test(value) &&
            /[A-Z]/.test(value) &&
            /\d/.test(value)
          );
        }
      }
    },
    forgotPasswordForm: {
      email: { required, email }
    }
  }
})
export default class CheckoutUserLogin extends Mixins(AuthMixin) {
  @Prop() readonly car!: Car;
  @Prop() readonly noReservation?: boolean;
  @Prop() readonly from!: Route;

  @Prop({ default: RegisterForm }) private selectedForm!: FormType;
  private currentlySelectedForm: FormType = RegisterForm;

  public form = {
    email: '',
    password: ''
  };
  public forgotPasswordForm = {
    email: ''
  };
  public loading = {
    register: false,
    forgotPassword: false
  };

  get queryFrom(): string {
    return this.$route.query.from.toString() ?? '';
  }

  get queryTo(): string {
    return this.$route.query.to.toString() ?? '';
  }

  get queryShowLoginForm(): boolean {
    return this.$route.query.login === '1';
  }

  get isForgotPasswordForm(): boolean {
    return this.currentlySelectedForm === ForgotPasswordForm;
  }

  get isLoginForm(): boolean {
    return this.currentlySelectedForm === LoginForm;
  }

  get isRegisterForm(): boolean {
    return this.currentlySelectedForm === RegisterForm;
  }

  public showRegistrationSuccess = false;
  public registrationEmail = '';

  validate() {
    this.$v.form.$touch();
    return !this.$v.form.$invalid;
  }

  created() {
    if (this.queryShowLoginForm) {
      this.switchToLogin();
    }
  }

  mounted() {
    if (this.loggedUser !== undefined) {
      this.$toast.success('Už jste přihlášen');
      this.redirectBack();
      return;
    }
    if (this.queryShowLoginForm) {
      this.switchToLogin();
    } else {
      this.currentlySelectedForm = this.selectedForm;
    }
  }

  validateForgotPassword() {
    this.$v.forgotPasswordForm.$touch();
    return !this.$v.forgotPasswordForm.$invalid;
  }

  validationMessage(field: string): string | null {
    const validation = this.isForgotPasswordForm ? this.$v.forgotPasswordForm[field] : this.$v.form[field];
    if (!validation || !validation.$error) {
      return null;
    }

    switch (field) {
      case 'email':
        return 'Zadejte email';

      case 'password':
        if (this.form.password)
          return 'Heslo nesplňuje tyto požadavky: minimálně 8 znaků dlouhé, 1 velké písmeno, 1 malé písmeno a 1 číslice';
        return 'Zadejte heslo';
    }

    return null;
  }

  hasPasswordValidationError() {
    return this.$v.form.password?.$error;
  }

  async login() {
    try {
      if (!this.validate()) {
        return;
      }
      await UserService.login(this.form.email, this.form.password);
      store.dispatch('fetchLoggedUser');
      this.$toast.success('Jste přihlášen');
    } catch (e) {
      this.$toast.error((e as Error).message);
      return;
    }

    if (this.noReservation) {
      this.redirectBack();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async loginGoogleUser(data: any) {
    try {
      await UserService.loginGoogleUser(data.email, data.idToken);
      store.dispatch('fetchLoggedUser');
      this.$toast.success('Jste přihlášen');
    } catch (e) {
      const error = e as Error;
      console.error(error);
      this.$toast.error('Nepodařilo se zpracovat požadavek na přihlášení uživatele přes služby Google.');
      return;
    }

    if (this.noReservation) {
      this.redirectBack();
    }
  }

  async register() {
    if (!this.validate()) {
      return;
    }

    this.loading.register = true;
    try {
      const response = await UserService.register(
        this.form.email,
        this.form.password,
        this.noReservation ? undefined : this.getReservationParameters()
      );
      if (response.logged) {
        store.dispatch('fetchLoggedUser');
        if (this.noReservation) {
          this.redirectBack();
        }
        return;
      }
      this.registrationEmail = this.form.email;
      window.scrollTo(0, 0);
      this.showRegistrationSuccess = true;
      this.form = {
        email: '',
        password: ''
      };
      this.$v.form.$reset();
    } catch (error) {
      this.$toast.error((error as Error).message);
    } finally {
      this.loading.register = false;
    }
  }

  switchToRegistration() {
    this.currentlySelectedForm = RegisterForm;
    this.$v.$reset();
    this.form.password = '';
  }

  switchToLogin() {
    this.currentlySelectedForm = LoginForm;
    this.$v.$reset();
    this.form.password = '';
  }

  switchToForgotPassword(e: Event) {
    e.preventDefault();
    this.$v.$reset();
    this.currentlySelectedForm = ForgotPasswordForm;
    this.form.password = '';
  }

  goBack() {
    this.switchToRegistration();
    this.redirectBack();
  }

  hideRegistrationSuccess() {
    this.showRegistrationSuccess = false;
  }

  async submitForgotPassword() {
    if (!this.validateForgotPassword()) {
      return;
    }

    this.loading.forgotPassword = true;
    try {
      const response = await UserService.forgotPassword(
        this.forgotPasswordForm.email,
        this.noReservation ? undefined : this.getReservationParameters()
      );
      if (response) {
        this.switchToLogin();
        this.$toast.success('Na e-mail jsme vám odeslali instrukce k obnovení hesla.');
      } else {
        this.$toast.error('Nepodařilo se zpracovat požadavek.');
      }
    } catch (error) {
      this.$toast.error((error as Error).message);
    } finally {
      this.loading.forgotPassword = false;
    }
  }

  getReservationParameters() {
    return {
      from: this.queryFrom,
      to: this.queryTo,
      carId: this.car._id
    };
  }

  private redirectBack() {
    if (this.from?.path) {
      this.$router.push({
        path: this.from.path,
        query: this.from.query,
        params: this.from.params
      });
    } else {
      this.$router.push({ name: HomeRoute });
    }
  }
}
