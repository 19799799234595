










import { Component, Vue } from 'vue-property-decorator';
import Button from '@/components/ui/Button.vue';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
@Component({
  components: { Button }
})
export default class GoogleSignIn extends Vue {
  public isInit = false;
  private isSignIn = false;

  private firebaseConfig = {
    apiKey: 'AIzaSyANg0UMHSmKO47WvL-0BRvDqzu9M2rtpGc',
    authDomain: 'auth.kakadoo.cz',
    projectId: 'kakadoo-303415',
    storageBucket: 'kakadoo-303415.appspot.com',
    messagingSenderId: '685178565742',
    appId: '1:685178565742:web:576e34a9056f1b9925a27f',
    measurementId: 'G-5NDC1ESMBM'
  };

  mounted() {
    setTimeout(() => {
      // MODULAR SYNTAX v9+ / Initialize Firebase
      const app = initializeApp(this.firebaseConfig);
      const auth = getAuth(app);
      this.isInit = !!app;
      this.isSignIn = auth.currentUser !== null;
    }, 1000);
  }

  async handleClickSignIn() {
    if (this.isSignIn) {
      this.emitLoggedUser();
    } else {
      await this.signIn();
    }
  }

  private async emitLoggedUser() {
    const auth = getAuth();
    if (auth.currentUser !== null) {
      this.$emit('loggedUser', {
        email: auth.currentUser.email,
        idToken: await auth.currentUser.getIdToken()
      });
    }
  }

  private async signIn(): Promise<void> {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    try {
      const googleUser = await signInWithPopup(auth, provider);
      if (!googleUser) {
        return;
      }
      this.$emit('loggedUser', {
        email: googleUser.user.email,
        idToken: await googleUser.user.getIdToken()
      });
      this.isSignIn = !!googleUser;
    } catch (error) {
      // on fail do something
      console.error('SignIn Error: ', error);
      return;
    }
  }
}
