import { AxiosError } from 'axios';

export class AppError extends Error {
  public constructor(message: string, public error: string, public type?: string, public cause?: Error | AxiosError) {
    super(message);
  }
}
export enum AppErrorName {
  NotApplicable = 'DiscountNotApplicableError',
  NotFound = 'DiscountCodeNotFoundError'
}

export enum DiscountsErrorType {
  TotalPriceExceeded = 'discounts.total-price-exceeded',
  NotFound = 'discounts.not-found',
  CityNotMatching = 'discounts.city-not-matching',
  UserEmailNotMatching = 'discounts.user.email-not-matching',
  MaxNumberOfUsesExceeded = 'discounts.max-number-of-uses-exceeded',
  CodeAppliedOutsideSalePeriod = 'discounts.code-applied-outside-its-sale-period',
  SingleUsePerUserCodeUsedRepeatedly = 'discounts.single-use-per-user-code-used-repeatedly',
  CarIdNotMatching = 'discounts.car-id-not-matching'
}

export function toAppError(err: Error | AxiosError | unknown) {
  if (err instanceof Error && 'response' in err) {
    const response = (err as AxiosError).response?.data;
    if ('error' in response) {
      throw new AppError((err as Error).message, response.error, response.type, err);
    }
  }
  throw err;
}
