

































































































import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import { MapPinIcon, ArrowRightIcon, CalendarIcon, TagIcon } from 'vue-feather-icons';
import Button from '@/components/ui/Button.vue';
import TextField from '@/components/ui/form/TextField.vue';

import { Car } from '@/store/types';
import moment from 'moment';
import { AppliedDiscount, validateEligibleDiscountCode } from '@/services/reservations.service';
import { AppError, AppErrorName, toAppError, DiscountsErrorType } from '@/services/errorHandling';
import { DEFAULT_DATE_FORMAT } from '@/utils/consts';

@Component({
  components: {
    MapPinIcon,
    ArrowRightIcon,
    CalendarIcon,
    TagIcon,
    Button,
    TextField
  }
})
export default class SummaryBox extends Vue {
  @Prop({ required: true }) readonly car!: Car;
  @Prop({ required: true }) readonly from!: string;
  @Prop({ required: true }) readonly to!: string;
  @Prop({ required: false, default: false }) showDiscountButton!: boolean;
  @Ref('discountInput') readonly discountInput!: TextField;

  private discountCode = '';
  private daysCount = 0;

  public showDiscountCodeBox = false;
  private errorMessage: string | null = '';

  mounted(): void {
    this.daysCount = moment(this.to.toString()).diff(moment(this.from.toString()), 'days') + 1;
  }

  get activeDiscount(): AppliedDiscount | null {
    return this.$store.state.activeDiscount;
  }

  get reservationPrice(): number {
    return (
      Math.round(
        (this.car.overallPrice - (this.activeDiscount ? this.activeDiscount.priceDiscountedByAmount : 0)) * 10
      ) / 10
    );
  }

  get start() {
    return moment(this.$route?.query?.from?.toString(), DEFAULT_DATE_FORMAT).format('D.M.YYYY') || '';
  }

  get end() {
    return moment(this.$route?.query?.to?.toString(), DEFAULT_DATE_FORMAT).format('D.M.YYYY') || '';
  }

  get visibleCarParameters() {
    return this.car.parameters.filter((item) => item.inCheckoutSummary);
  }

  formatDayCount(count: number) {
    const days = count === 1 ? 'den' : count < 5 ? 'dny' : 'dní';
    return `${count} ${days}`;
  }

  toggleShowDiscountCodeBox() {
    this.showDiscountCodeBox = !this.showDiscountCodeBox;
    if (this.showDiscountCodeBox) this.$nextTick(() => this.discountInput.focus());
  }

  deactivateDiscount() {
    this.$store.dispatch('setActiveDiscount', null);
  }

  async validateDiscountCode() {
    try {
      const eligibleDiscountCode = await validateEligibleDiscountCode(
        this.$route?.query?.from?.toString(),
        this.$route?.query?.to?.toString(),
        this.$route?.query?.car?.toString(),
        this.discountCode
      ).catch(toAppError);
      this.showDiscountCodeBox = false;
      this.errorMessage = null;
      await this.$store.dispatch('setActiveDiscount', eligibleDiscountCode);
      if (this.reservationPrice <= 50) {
        this.$toast.warning('Minimální cena objednávky je 50 Kč. Cena byla upravena.');
      }
    } catch (e) {
      if (e instanceof AppError) {
        if (e.error === AppErrorName.NotApplicable) {
          switch (e.type) {
            case DiscountsErrorType.MaxNumberOfUsesExceeded:
              this.errorMessage = 'Slevový kód již byl vyčerpán';
              break;
            case DiscountsErrorType.CodeAppliedOutsideSalePeriod:
              this.errorMessage = 'Slevový kód nelze uplatnit ve zvoleném termínu';
              break;
            case DiscountsErrorType.CarIdNotMatching:
              this.errorMessage = 'Slevový kód nelze uplatnit pro toto vozidlo';
              break;
            case DiscountsErrorType.SingleUsePerUserCodeUsedRepeatedly:
              this.errorMessage = 'Slevový kód byl již využit';
              break;
            default:
              this.errorMessage = 'Neplatný slevový kód';
          }
        } else {
          this.errorMessage = 'Neplatný slevový kód';
        }
      } else {
        this.errorMessage = 'Ověřování kódu se nezdařilo';
        console.error('could not validate discount code', e);
      }
    }
  }
}
